.ql-custom {
  background-color: #3f2faa;
  height: 211px;
}

.ql-custom .ql-toolbar.ql-snow,
.ql-custom .ql-container.ql-snow {
  border: none;
}

.ql-custom .ql-toolbar.ql-snow {
  border-bottom: 1px solid #6351DD;
}

.ql-custom .ql-toolbar .ql-stroke {
  fill: none;
  stroke: #A498ED;
}

.ql-custom .ql-header {
  color: #A498ED !important;
}

.ql-custom .ql-container {
  height: 70%;
}