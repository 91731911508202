
.custom-calendar-container {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 4px;
}

.home-calendar-container {
  font-size: 13px;
  padding-left: 3px;
}

.custom-calendar-container div {
  border: none !important;
}

.react-datetime-picker--disabled {
  background-color: transparent !important;
  color: #ffffff80 !important;
}

.custom-calendar {
  border-radius: 8px !important;
  width: 295px !important;
  padding: 20px;
  margin-top: 10px;
  margin-left: -8px;
}

.react-calendar__tile {
  color: #4a5660 !important;
  font-size: 14px;
  font-weight: 550 !important;
}
.react-calendar__tile--active {
  background-color: #6c47ff !important;
  border-radius: 6px;
  width: 30px !important;
  color: #fff !important;
}

.react-calendar__month-view__weekdays {
  color: #b5bec6 !important;
}

.react-calendar__month-view__weekdays abbr {
  text-decoration: none !important;
}

.react-calendar__navigation button {
  min-width: unset !important;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__label__labelText {
  color: #4a5660 !important;
  font-weight: 550 !important;
  font-size: 14px;
  line-height: 14px;
}


.react-datetime-picker__wrapper input::placeholder {
  color: #ffffff80;
}


/* //////////////////////////// */


.month-year-calendar {
  border-radius: 9px !important;
  padding: 24px 12px;
}

.react-datepicker__month-text {
  height: 30px;
  padding-top: 6px;
}
.react-datepicker__month {
  color: #4a5660 !important;
  font-size: 14px;
  font-weight: 550 !important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: #6c47ff !important;
  border-radius: 6px;
  color: #fff !important;
}


.react-datepicker__navigation {
  top: 24px !important;
  color: red !important;
}

.react-datepicker__header.react-datepicker-year-header {
  background-color: #FFFFFF !important;
}