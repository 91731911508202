@font-face {
  font-family: Gotham;
  src: url(./fonts/Gotham-Book.otf);
  format: ("opentype");
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "Gotham";
  color: white;
  margin: 0;
}

h1 {
  font-size: 16px;
}

a {
  text-decoration: none;
  color: inherit;
}

code {
  font-family: Gotham, source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.match-report-popover .ant-popover-title {
  color: #fff;
  font-size: 16px;
  font-weight: 325;
}

.player-remove-popover .ant-popover-title {
  color: #fff;
  font-size: 16px;
  font-weight: 325;
  text-align: center;
  max-width: 240px;
}

.brackets-page-dropdown {
  width: 217px !important;
}

.player-details-modal-options {
  background-color: #4e3cca !important;
  border-radius: 20px;
  height: 23px !important;
}

@media (max-width: 320px) {
  .brackets-page-dropdown {
    width: 80px !important;
  }
}
@media (max-width: 468px) {
  .brackets-page-dropdown {
    width: 100px !important;
  }

  .flex-col-mobile {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}

.hidden {
  display: none;
}

.horizontal-scroll {
  padding-bottom: 10px;
}

.horizontal-scroll::-webkit-scrollbar {
  height: 6px;
  padding-top: 10px;
  position: absolute;
  bottom: 0;
}

.horizontal-scroll::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 3px;
}

.horizontal-scroll::-webkit-scrollbar-thumb {
  background: #2b1c7e;
  padding: 0 4px;
  border-radius: 24px;
}

.horizontal-scroll::-webkit-scrollbar-thumb:hover {
  background: #3f338c;
}


.ant-select-dropdown {
  background-color: #3f2faa;
}